
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {};
  },
  props: {
    startingLevel: {
      type: String,
      required: false,
      default: "Free",
    },
  },
  data() {
    return {
      intLevel: this.startingLevel,
    };
  },
  emits: ["changed"],
  computed: {
    levelOptions(): string[] {
      return ["Free", "Basic", "Gold"];
    },
    level: {
      get(): string {
        return this.intLevel;
      },
      set(newVal: string): void {
        this.intLevel = newVal;
        this.$emit("changed", newVal);
      },
    },
  },
});
