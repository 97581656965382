import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "pl-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_radio = _resolveComponent("va-radio")!
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_popover = _resolveComponent("va-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levelOptions, (option) => {
      return (_openBlock(), _createBlock(_component_va_radio, {
        key: option,
        modelValue: _ctx.level,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.level) = $event)),
        option: option
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(option), 1)
        ]),
        _: 2
      }, 1032, ["modelValue", "option"]))
    }), 128)),
    _createVNode(_component_va_popover, {
      placement: "right",
      message: "Select the staking level a user must have to subscribe."
    }, {
      default: _withCtx(() => [
        _createVNode(_component_va_icon, {
          class: "mb-3",
          name: "info"
        })
      ]),
      _: 1
    })
  ]))
}